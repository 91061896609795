import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import Table from '../../components/table';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';
import OrderTableRow from '../../components/order-table-row';

import WithPermission, { importOrders } from '../with-permission';

import OrderImporter from '../order-importer';

import withAPI from '../../hocs/with-api';
import Input from '../../components/input';
import SuccessAlert from '../../components/success-alert';
import HttpErrorHandler from '../../components/http-error-handler';
import FlashMessage from '../../components/flash-message';
import DropdownFilter from '../../components/dropdown-filter';
import withUserInfo from '../../hocs/with-user-info';

const searchInputIconStyle = {
  backgroundImage: "url('/assets/search-icon.png')",
  backgroundSize: '15px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 21px center',
};


class Orders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      importBlockShown: false,
      currentPage: getCurrentPageNumber(props),
      orders: [],
      orderNumber: '',
      carriers: [],
      statuses: [],
    };
  }

  async componentDidMount() {
    const { t, user } = this.props;
    document.title = `${t('orders.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadOrders();
    if (user.isAdmin) {
      this.loadCarriers();
    }
    if (user.isAdmin) {
      this.loadStatues();
    }
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  onOrderNumberChanged = ({ target: { value } }) => {
    this.orderNumberChange(value);
  }

  orderNumberChange = _.debounce((value) => {
    const { history } = this.props;
    this.setState({
      orderNumber: value,
      currentPage: 1,
    }, () => {
      history.push('/orders');
      this.loadOrders();
    });
  }, 500)

  onImportButtonClick = () => this.setState((prevState) => ({
    importBlockShown: !prevState.importBlockShown,
  }))

  onImport = (importedOk, importFeedback) => {
    this.setState({
      importedOk,
      importFeedback,
      importFailed: !importedOk,
    });
  }

  loadOrders = async () => {
    const {
      httpRequest,
    } = this.props;
    const {
      currentPage,
      orderNumber,
      filterCarrierId,
      filterStatusId,
    } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `orders?page=${currentPage}&search=${orderNumber}&carrierId=${filterCarrierId ?? null}
      &statusId=${filterStatusId ?? null}`,
    });
    if (response) {
      const {
        data: {
          data: orders,
          total,
          per_page,
          last_page,
          from,
          to,
        },
      } = response;
      this.setState({
        orders,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/orders/${currentPage}`);
      this.loadOrders();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadOrders);
  }

  closeSuccessAlert = () => {
    this.setState({ importedOk: false });
  }

  closeErrorAlert = () => {
    this.setState({ importFailed: false });
  }

  async loadCarriers() {
    const { httpRequest } = this.props;
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });
    if (carriers) {
      this.setState({
        carriers,
      });
    }
  }

  onCarrierSelected = (carrierId) => {
    this.setState({
      filterCarrierId: carrierId,
      currentPage: 1,
    }, this.loadOrders);
  }

  loadStatues = async () => {
    const { httpRequest } = this.props;
    const { data: statuses } = await httpRequest({
      method: 'get',
      url: 'statuses',
    });
    this.setState({ statuses });
  }

  onStatusSelected = (statusId) => {
    this.setState({
      filterStatusId: statusId,
      currentPage: 1,
    }, this.loadOrders);
  }

  render() {
    const {
      httpGetLoading,
      t,
      httpGetFailed,
      httpGetDone,
      user,
    } = this.props;
    const {
      orders,
      currentPage,
      totalPages,
      perPage,
      totalRecords,
      from,
      to,
      importBlockShown,
      importedOk,
      importFailed,
      importFeedback,
      carriers,
      statuses,
    } = this.state;

    const filterOptions = _.map(carriers, (carrier) => ({
      id: carrier.id,
      label: carrier.name,
      value: carrier.id,
    }));

    const filterStatusOptions = _.map(statuses, (status) => ({
      id: status.id,
      label: status.name,
      value: status.id,
    }));

    return (
      <>
        <ActionCard
          title={t('orders.viewAll')}
          actionButtonLabel={t('orders.import')}
          withActionButton={false}
        >
          <div className="row mb-3">
            {user?.isAdmin && (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
                  <DropdownFilter
                    dropDownStyles={{ toggleStyles: { width: '100%' }, toggleParentStyles: { width: '100%' } }}
                    options={filterOptions}
                    label={t('carriers.one')}
                    onSelected={this.onCarrierSelected}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
                  <DropdownFilter
                    dropDownStyles={{ toggleStyles: { width: '100%' }, toggleParentStyles: { width: '100%' } }}
                    options={filterStatusOptions}
                    label={t('orders.status')}
                    onSelected={this.onStatusSelected}
                  />
                </div>
              </>
            )}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              <Input
                name="orderNumber"
                id="orderNumber"
                placeholder={t('orders.search.placeholder')}
                type="text"
                style={{ height: '40px', width: '100%', ...searchInputIconStyle }}
                className="input-clean float-right"
                onChange={this.onOrderNumberChanged}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              <WithPermission permission={importOrders}>
                <OrderImporter buttonImportStyles={{ width: '100%', marginLeft: '0px' }} onImport={this.onImport} />
              </WithPermission>
            </div>
          </div>
          {
            httpGetLoading && <Spinner marginTop="555px" />
          }
          {
            <FlashMessage />
          }
          {
            importedOk && (
              <SuccessAlert onClose={this.closeSuccessAlert} message={importFeedback} />
            )
          }
          {
            importFailed && (
              <HttpErrorHandler onClose={this.closeErrorAlert} {...importFeedback} />
            )
          }
          {
            httpGetFailed && (
            <HttpError
              withTryAgainButton
              onTryAgain={this.loadOrders}
            />
            )
          }
          {
            importBlockShown && (
              <OrderImporter onImportDoneCallback={this.loadOrders} />
            )
          }
          { !httpGetLoading && !httpGetFailed && httpGetDone && orders && (
            <>
              <Table
                isEmpty={_.isEmpty(orders)}
                emptyMessage={t('orders.notFound')}
              >
                <thead>
                  <tr>
                    <th className="align-middle">{ `# ${t('orders.number')}` }</th>
                    <th className="align-middle hideMobile">{t('clients.shortName')}</th>
                    <th className="align-middle hideMobile">{ t('orders.status') }</th>
                    <th className="align-middle actions contentRight">{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  { _.map(orders, (order) => (
                    <OrderTableRow hasViewLink {...order} key={`order-${order.id}`} />
                  )) }
                </tbody>
              </Table>
              {
                 totalPages
                 && (
                 <Pagination
                   currentPage={currentPage}
                   totalPages={totalPages}
                   onChange={this.handlePageChange}
                   totalRecords={totalRecords}
                   perPage={perPage}
                   from={from}
                   to={to}
                 />
                 )
               }
            </>
          )}
        </ActionCard>
      </>
    );
  }
}

Orders.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetFailed: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
};

export default compose(
  withTranslation(),
  withRouter,
  withUserInfo,
  withAPI,
)(Orders);
